<template>
  <ButtonBase
    v-bind="($attrs, $props)"
    base-classes="rounded-[48px]"
    secondary-style="bg-transparent text-primary-default disabled:shadow-none hover:text-primary-hover border border-solid border-primary-default hover:!border-primary-hover"
    disabled-style="bg-black/6 text-black/26 disabled:shadow-none cursor-not-allowed"
    ><slot
  /></ButtonBase>
</template>

<script lang="ts">
import props from '~/components/ui/interactive/ButtonBase.vue';

export default {
  props,
};
</script>
